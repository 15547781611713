@charset 'utf-8';

//////////Variables//////////

$font-1: Raleway, sans-serif;

$color-1: #000;
$color-2: #FFF;
$color-3: #EFEFEF;

$text_color: $color-1;

//////////Extend//////////
%clearfix {
  &:before,
    &:after {
        content: "";
        display: table;
    }
    &:after {
        clear: both;
    }
}

//////////Selection//////////
*::selection {
  background: $color-1 none repeat scroll 0 0;
  color: $color-2;
  outline: medium none;
  text-shadow: none;
}

//////////Structure//////////
* {
  box-sizing: border-box;
  color: $color-1;
  font-family: $font-1, sans-serif;
}

html {
  font-size: 8px;
}

@media (min-width: 992px) {
  html {
    font-size: 10px;
  }
}


body {
  background-color: $color-2;
  color: $text_color;
}

.container {
  @extend %clearfix;
  margin: 0 auto;
  max-width: 900px;
  padding: 0 20px;
  width: 100%;
}

@media (min-width: 992px) {
  .container {
    padding: 0 50px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
  > div {
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 10px;
    padding-left: 10px;
  }
}

.col-xs-1 {
  flex-basis: 8.333333%;
}
.col-xs-2 {
  flex-basis: 16.666667%;
}
.col-xs-3 {
  flex-basis: 25%;
}
.col-xs-4 {
  flex-basis: 33.333333%;
}
.col-xs-5 {
  flex-basis: 41.666667%;
}
.col-xs-6 {
  flex-basis: 50%;
}
.col-xs-7 {
  flex-basis: 58.333333%;
}
.col-xs-8 {
  flex-basis: 66.666667%;
}
.col-xs-9 {
  flex-basis: 75%;
}
.col-xs-10 {
  flex-basis: 83.333333%;
}
.col-xs-11 {
  flex-basis: 91.666667%;
}
.col-xs-12 {
  flex-basis: 100%;
}

@media (min-width: 992px) {
  .col-lg-1 {
    flex-basis: 8.333333%;
  }
  .col-lg-2 {
    flex-basis: 16.666667%;
  }
  .col-lg-3 {
    flex-basis: 25%;
  }
  .col-lg-4 {
    flex-basis: 33.333333%;
  }
  .col-lg-5 {
    flex-basis: 41.666667%;
  }
  .col-lg-6 {
    flex-basis: 50%;
  }
  .col-lg-7 {
    flex-basis: 58.333333%;
  }
  .col-lg-8 {
    flex-basis: 66.666667%;
  }
  .col-lg-9 {
    flex-basis: 75%;
  }
  .col-lg-10 {
    flex-basis: 83.333333%;
  }
  .col-lg-11 {
    flex-basis: 91.666667%;
  }
  .col-lg-12 {
    flex-basis: 100%;
  }
}


//////////Fonts//////////
h1 {
  font-size: 5rem;
  font-weight: 700;
  margin: 0 0 80px 0px;
}

h2 {
	font-size: 3rem;
  font-weight: 700;
  margin: 90px 0 50px 0;
}
@media (min-width: 992px) {
  h2 {
  	font-size: 2.6rem;
  }
}

h3 {
  font-size: 1.8rem;
  line-height: 1.75;
  margin: 0;
}

p {
  font-size: 2rem;
  line-height: 1.75;
  margin: 0 0 10px 0;
}

//////////Header//////////
header {
  @extend %clearfix;
  background-color:rgba(255, 255, 255, 0.9);
  padding: 50px 0 20px 0;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  #site-title {
    float: left;
    a {
      color: $text_color;
      text-decoration: none;
      font-size: 2rem;
      line-height: 2.2rem;
      span#name {
        font-weight: 700;
      }
      span#name,
      span#role,
      span#localisation {
        display: block;
      }
    }
  }
  #contacts {
    float: right;
    span {
      display: block;
      margin-bottom: 15px;
      text-align: right;
    }
    img {
      filter: brightness(0.2);
      margin-right: 5px;
    }
    a {
      color: $text_color;
      text-decoration: none;
      font-size: 1.8rem;
      position: relative;
    }
    a::after {
      content: '';
      position: absolute;
      width: 100%;
      transform: scaleX(0);
      height: 1px;
      bottom: 0;
      left: 0;
      background-color: $text_color;
      transform-origin: bottom right;
      transition: transform 0.25s ease-out;
    }
    a:hover::after {
      transform: scaleX(1);
      transform-origin: bottom left;
    }
  }
}

@media (min-width: 992px) {
  header {
    #contacts {
      span {
        margin-bottom: 5px;
      }
    }
  }
}

#banner {
  @extend %clearfix;
  margin-top: 200px;
  img {
    max-width: 80%;
    height: auto;
  }
  h1 {
  }
}

//////////Article/////////

article {
  padding-bottom: 50px;
  .career {
    ul {
      margin: 0;
      padding: 0;
      li {
        border-left: 5px solid $color-3;
        list-style: none;
        margin: 5px 0 15px 0;
        padding-left: 15px;
      }
    }
    span.date {
      display: block;
      font-size: 1.8rem;
      line-height: 3rem;
      text-align: right;
    }
    margin: 0 0 40px 0;
    span.business,
    span.customers {
      display: block;
      font-size: 1.8rem;
      line-height: 3rem;
      span.customers-client,
      span.customers-project,
      span.customers-date,
      span.customers-localisation {
        display: block;
        font-size: 1.5rem;
        line-height: 2.3rem;
      }
    }
  }
}


//////////Footer/////////
footer.container {
  border-top: 1px solid $color-3;
  padding: 40px 50px;
  p {
    line-height: 2rem;
    font-size: 1.4rem;
  }
}
